import React, { useEffect, useState } from "react";
import Card from "components/card";
import { Button, Spinner } from "@material-tailwind/react";
import { getAllRecouvryEEApi, getAllRecouvryEEXcelApi } from "api/recovry";

import API_URL from "config";

function EncaissementsStatusTable() {
  const [tableData, setTableData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingXcel, setIsLoadingXcel] = useState(false);

  const [selectedYear, setSelectedYear] = useState(new Date().getUTCFullYear());

  const [inputFilterValue, setInputFilterValue] = useState({
    typeOfOp: "",
    clientName: "",
    clientCode: "",
    fromDate: "",
    toDate: "",
  });

  useEffect(() => {
    allTableData(
      inputFilterValue.clientName,
      inputFilterValue.typeOfOp,
      "",
      inputFilterValue.fromDate,
      inputFilterValue.toDate,
      selectedYear,
      inputFilterValue.clientCode
    );
  }, []);

  const allTableData = async (
    clientName,
    typeOfOp,
    E_I,
    fromDate,
    toDate,
    selectedYear,
    clientCode
  ) => {
    try {
      setIsLoading(true);

      const response = await getAllRecouvryEEApi(
        clientName,
        typeOfOp,
        E_I,
        fromDate,
        toDate,
        selectedYear,
        clientCode
      );

      setTableData(response);
    } catch (err) {
      console.log("err", err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFiltersInputChange = (e) => {
    const { name, value } = e.target;
    setInputFilterValue({ ...inputFilterValue, [name]: value });
    allTableData(
      name === "clientName" ? value : inputFilterValue.clientName,
      name === "typeOfOp" ? value : inputFilterValue.typeOfOp,
      "",
      name === "fromDate" ? value : inputFilterValue.fromDate,
      name === "toDate" ? value : inputFilterValue.toDate,
      selectedYear,
      name === "clientCode" ? value : inputFilterValue.clientCode
    );
  };

  const handleXcelDownload = async () => {
    try {
      setIsLoadingXcel(true);
      const response = await getAllRecouvryEEXcelApi(
        inputFilterValue.clientName,
        inputFilterValue.typeOfOp,
        "",
        inputFilterValue.fromDate,
        inputFilterValue.toDate,
        selectedYear,
        inputFilterValue.clientCode
      );

      await new Promise((resolve) => {
        setTimeout(() => {
          window.open(`${API_URL}/${response}`, "_blank");
          resolve();
        }, 1000);
      });
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoadingXcel(false);
    }
  };

  return (
    <Card extra={"w-full pb-10 p-4 h-full"}>
      <header className="relative flex flex-col items-center justify-between md:flex-row">
        <div className="mb-5 text-xl font-bold text-navy-700 dark:text-white md:mb-0"></div>
        {isLoadingXcel ? (
          <div className="flex items-center justify-center">
            <Spinner className="h-12 w-12" />
          </div>
        ) : (
          <Button
            className="linear mb-5 rounded-[10px] bg-green-500 px-4 py-2 text-xs font-bold text-white transition duration-200 hover:bg-green-400 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:mb-0"
            onClick={handleXcelDownload}
          >
            Télécharger excel
          </Button>
        )}
      </header>
      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-4">
        <div>
          <small className="text-sm font-medium text-gray-600 dark:text-white">
            Modalité de paiement :
          </small>
          <div>
            <select
              label="select Version"
              className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              defaultValue=""
              name="typeOfOp"
              value={inputFilterValue.typeOfOp}
              onChange={handleFiltersInputChange}
            >
              <option value="">Tout</option>
              <option value="Cheque">Cheque</option>
              <option value="Effet">Effet</option>
              <option value="Espece">Espece</option>
              <option value="Virement">Virement</option>
            </select>
          </div>
        </div>
        <div>
          <small className="text-sm font-medium text-gray-600 dark:text-white">
            Client :
          </small>
          <div>
            <input
              type="text"
              id=""
              placeholder="Tout"
              className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              name="clientName"
              value={inputFilterValue.clientName}
              onChange={handleFiltersInputChange}
            />
          </div>
        </div>
        <div>
          <small className="text-sm font-medium text-gray-600 dark:text-white">
            Code client :
          </small>
          <div>
            <input
              type="text"
              id=""
              placeholder="Tout"
              className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              name="clientCode"
              value={inputFilterValue.clientCode}
              onChange={handleFiltersInputChange}
            />
          </div>
        </div>
        <div>
          <small className="text-sm font-medium text-gray-600 dark:text-white">
            Période (Depuis - à) :
          </small>
          <div>
            <input
              type="date"
              id=""
              placeholder=""
              className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              name="fromDate"
              value={inputFilterValue.fromDate}
              onChange={handleFiltersInputChange}
            />
            <input
              type="date"
              id=""
              placeholder=""
              className={`mt-2 flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              name="toDate"
              value={inputFilterValue.toDate}
              onChange={handleFiltersInputChange}
            />
          </div>
        </div>
      </div>

      <div className="mt-8 overflow-x-scroll">
        {isLoading ? (
          <div className="flex w-full items-center justify-center">
            <Spinner className="h-12 w-12" />
          </div>
        ) : (
          <div
            className="mt-8 overflow-x-scroll"
            style={{ height: "50vh", overflowY: "scroll" }}
          >
            <table className="w-full">
              <thead>
                <tr>
                  <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                    <p className="text-xs tracking-wide text-gray-600">Date</p>
                  </th>
                  <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                    <p className="text-xs tracking-wide text-gray-600">
                      Référence
                    </p>
                  </th>
                  <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                    <p className="text-xs tracking-wide text-gray-600">
                      Code client
                    </p>
                  </th>
                  <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                    <p className="text-xs tracking-wide text-gray-600">
                      Code comptable
                    </p>
                  </th>
                  <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                    <p className="text-xs tracking-wide text-gray-600">
                      Nom du client
                    </p>
                  </th>
                  <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                    <p className="text-xs tracking-wide text-gray-600">
                      Modalité de paiement
                    </p>
                  </th>
                  <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                    <p className="text-xs tracking-wide text-gray-600">
                      Montant reçu
                    </p>
                  </th>
                  <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                    <p className="text-xs tracking-wide text-gray-600">
                      Numéro de compte
                    </p>
                  </th>
                </tr>
              </thead>

              <tbody>
                {tableData.map((el) => (
                  <tr>
                    <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                      <p className="text-sm font-bold text-navy-700 dark:text-white">
                        {
                          el.value.EncaissementDate ?
                            `
                              ${new Date(el.value.EncaissementDate).getDate().toString().padStart(2, '0')}-${(new Date(el.value.EncaissementDate).getMonth()+1).toString().padStart(2, '0')}-${new Date(el.value.EncaissementDate).getFullYear()}
                            `
                          :
                            ``
                        }
                      </p>
                    </td>
                    <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                      <p className="text-sm font-bold text-navy-700 dark:text-white">
                        {el.value.transactionNumber}
                      </p>
                    </td>
                    <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                      <p className="text-sm font-bold text-navy-700 dark:text-white">
                        {el.value.codeClient}
                      </p>
                    </td>
                    <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                      <p className="text-sm font-bold text-navy-700 dark:text-white">
                        {el.value.codeComptable}
                      </p>
                    </td>
                    <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                      <p className="text-sm font-bold text-navy-700 dark:text-white">
                        {el.value.client}
                      </p>
                    </td>
                    <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                      <p className="text-sm font-bold text-navy-700 dark:text-white">
                        {el.key}
                      </p>
                    </td>
                    <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                      <p className="text-sm font-bold text-navy-700 dark:text-white">
                        {parseFloat(el.value.Montant).toFixed(2)}
                      </p>
                    </td>
                    <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                      <p className="text-sm font-bold text-navy-700 dark:text-white">
                        {el.value.transactionNumber}
                      </p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </Card>
  );
}

export default EncaissementsStatusTable;
