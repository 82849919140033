import React, { useEffect, useState } from "react";
import Card from "components/card";
import {
  Dialog,
  DialogHeader,
  DialogBody,
  Button,
  Spinner,
} from "@material-tailwind/react";

import { ToastContainer, toast } from "react-toastify";

import CV from "./Company_fees_tables/CV";
import FD from "./Company_fees_tables/FD";
import FTL from "./Company_fees_tables/FTL";

import CVModale from "./Modales/CV_modale";
import FDModale from "./Modales/FD_modale";
import FTLModale from "./Modales/FTL_modale";
import { getAllCompanyFeesApi } from "api/companyFees";
import { getAllOperatorsApi } from "api/filtersData";
import { pdfCompanyFeeGeneratorApi } from "api/pdf";

function CompanyFeesTable({ accessList }) {
  const [listOfTcp, setListOfTcp] = useState([]);

  const [showCompanyFeesModaleCv, setShowCompanyFeesModaleCv] = useState(false);
  const [showCompanyFeesModaleFd, setShowCompanyFeesModaleFd] = useState(false);
  const [showCompanyFeesModaleFtl, setShowCompanyFeesModaleFtl] = useState(false);

  const [selectedFilters, setSelectedFilters] = useState(null)

  const [filtredObject, setFiltredObject] = useState({
    typeOfOp: "CV",
    isAproved: "",
    isJustify: "",
    responsable: "",
    cvValidationStaus: "",
    fdValidationStatus: "",
    tlValidationStatus: "",
    dgValidationStatus: "",
    details: "",
    description: "",
    selectedFromDate: `${new Date().getFullYear()}-01-01`,
    selectedToDate: `${new Date().getFullYear()}-12-31`,
    payeStatus: ""
  });

  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getFilterData();
  }, []);

  useEffect(() => {
    getAllCompanyFees(filtredObject);
  }, []);

  const getFilterData = async () => {
    try {
      const response = await getAllOperatorsApi();
      setListOfTcp(response);
    } catch (err) {
      console.log(err);
    }
  };

  const refreshTableData = async () => {
    getAllCompanyFees(filtredObject);
  };

  const getAllCompanyFees = async (filtredObject) => {
    try {
      setIsLoading(true);
      const response = await getAllCompanyFeesApi(filtredObject);
      setTableData(response);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFiltersInputChange = (e) => {
    const { name, value } = e.target;
    setFiltredObject((prevInputFilterValue) => ({
      ...prevInputFilterValue,
      [name]: value,
    }));

    if (name === "isAproved") filtredObject.isAproved = value;

    if (name === "isJustify") filtredObject.isJustify = value;

    if (name === "responsable") filtredObject.responsable = value;

    if (name === "typeOfOp") filtredObject.typeOfOp = value;

    if (name === "selectedFromDate") filtredObject.selectedFromDate = value;

    if (name === "selectedToDate") filtredObject.selectedToDate = value;

    if (name === "payeStatus") filtredObject.payeStatus = value;

    if(name === "dgValidationStatus")  filtredObject.dgValidationStatus = value;

    // getAllCompanyFees(filtredObject);
    // setSelectedFilters(filtredObject)

    // http://localhost:3005/api/V2/pdfGenerator/companyFees/616494554654dbcc076fde1c/05fd3ceb0984542181c606f11652452e/6d98ffb8037452250c621c150c864ac919c33fd3dfe6b595466d7ff24ebac72e312d9e21f97c55b23d3a68415873b5da779c314e546540c52ab85a6cb45221acb3a26d7612d3adf5dba089144dfc85623d30645af018657719eefbd8b3b35f221b8ec8e9bf92963fafd9ceb39d7f81141cf8b99a5acde3f30601c06ae3985a674c1cca6438a7600a921ef987c39296dc57a118f420988038db10a9704d2e40b45e230f7134873c4b2d1175889e3d45407000489f4ca465600b44cc124cf8baeacc38739d2b4d5ab1ad6058db237e225173966a2dcb2c4675d67223bd6b9aab0d77a0e24f622c35c84f0c932a1c12465e25ed0d5fddca978370a6a91d63ee0beddd437dbf1abfa93e8bffdb596903359ffbf888a1b6eb2ca21145e76d79a7c16249eee27aa75798175f8b653dc20dfe70c913c0b0d29e08a89cdf33d4cb0897a3c1cfbdac83efd7e95fe97d2499b094eef8b9c1f4cd7cd4ed0ce2020b42f67d5d116b9179d596a6024ade10308faed491ce3fb5815d65a6bc5b8976222413badfe918e1ad369d9f58f29eb78d71ad6bcb2c04a0d6d967177efb2a0ca0bf298dfc7fab467f8b4c6caeaaa7723234f355bbf29f94afda5540a58ecf8b1bc98b19c40bcc88f6896eaa70a91a570d45d5125d15ab0f50d3e65a/67a36444265d71456cdc9b13/fraisdesociété
  };

  const handleShowToast = (type, message) => {
    console.log("handleShowToast")
    if (type === "success") {
      setShowCompanyFeesModaleCv(false);
      setShowCompanyFeesModaleFd(false);
      setShowCompanyFeesModaleFtl(false);

      toast.success(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      getAllCompanyFees(filtredObject);
    }
    else if (type === "error") {
      toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handleCompanyFeeAddModaleCv = () => {
    try {
      setShowCompanyFeesModaleCv(!showCompanyFeesModaleCv);
    } catch (err) {
      console.log(err);
    }
  };

  const handleCompanyFeeAddModaleFd = () => {
    try {
      setShowCompanyFeesModaleFd(!showCompanyFeesModaleFd);
    } catch (err) {
      console.log(err);
    }
  };

  const handleCompanyFeeAddModaleFtl = () => {
    try {
      setShowCompanyFeesModaleFtl(!showCompanyFeesModaleFtl);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDownloadCompanyFeesPdf = async (filters) => {
    try {
      // console.log(filters)
      const response = await pdfCompanyFeeGeneratorApi(filters)
      window.open(`${response}`, '_blank')
    }
    catch (err) {
      console.log(err);
    }
  }

  const handleFilterCompanyFees = async () => {
    try {
      getAllCompanyFees(filtredObject)
    }
    catch (err) {
      console.log(err)
    }
  }

  return (
    <Card extra={"w-full pb-10 p-4 h-full"}>
      <ToastContainer />
      <Dialog
        size="xl"
        open={showCompanyFeesModaleCv}
        handler={handleCompanyFeeAddModaleCv}
      >
        <DialogHeader>
          <p className="w-full text-center">Charges véhicules</p>
        </DialogHeader>
        <DialogBody>
          <CVModale
            closePopup={handleCompanyFeeAddModaleCv}
            actionType="new"
            selectedData={null}
            showToast={handleShowToast}
          />
        </DialogBody>
      </Dialog>

      <Dialog
        size="xl"
        open={showCompanyFeesModaleFd}
        handler={handleCompanyFeeAddModaleFd}
      >
        <DialogHeader>
          <p className="w-full text-center">Frais de déplacement</p>
        </DialogHeader>
        <DialogBody>
          <FDModale
            closePopup={handleCompanyFeeAddModaleFd}
            actionType="new"
            selectedData={null}
            showToast={handleShowToast}
          />
        </DialogBody>
      </Dialog>

      <Dialog
        size="xl"
        open={showCompanyFeesModaleFtl}
        handler={handleCompanyFeeAddModaleFtl}
      >
        <DialogHeader>
          <p className="w-full text-center">Frais de transport local</p>
        </DialogHeader>
        <DialogBody>
          <FTLModale
            closePopup={handleCompanyFeeAddModaleFtl}
            actionType="new"
            selectedData={null}
            showToast={handleShowToast}
          />
        </DialogBody>
      </Dialog>

      <header className="relative flex flex-col items-center justify-between md:flex-row">
        <div className="mb-5 text-xl font-bold text-navy-700 dark:text-white md:mb-0"></div>
        {/* {
          accessList.Read &&
          <Button className="linear mb-5 rounded-[10px] bg-green-500 px-4 py-2 text-xs font-bold text-white transition duration-200 hover:bg-green-400 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:mb-0">
            Télécharger excel
          </Button>
        } */}
      </header>

      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-3">
        {accessList.VehicleCharges && accessList.Add && (
          <Button
            variant="text"
            color="#236571"
            className=""
            onClick={() => handleCompanyFeeAddModaleCv()}
          >
            <span>Ajouter charges véhicules</span>
          </Button>
        )}
        {accessList.TravellingExpenses && accessList.Add && (
          <Button
            variant="text"
            color="#236571"
            className=""
            onClick={() => handleCompanyFeeAddModaleFd()}
          >
            <span>Ajouter frais de déplacement</span>
          </Button>
        )}
        {accessList.LocalTransportationCosts && accessList.Add && (
          <Button
            variant="text"
            color="#236571"
            className=""
            onClick={() => handleCompanyFeeAddModaleFtl()}
          >
            <span>Ajouter frais de transport local</span>
          </Button>
        )}
      </div>

      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-4">
        <div>
          <small className="text-sm font-medium text-gray-600 dark:text-white">
            Etat (Approuvée) :
          </small>
          <div>
            <select
              label="select Version"
              className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              defaultValue=""
              name="isAproved"
              value={filtredObject.isAproved}
              onChange={handleFiltersInputChange}
            >
              <option value="">Tous</option>
              <option value="true">Approuvée</option>
              <option value="false">Non Approuvée</option>
            </select>
          </div>
        </div>
        <div>
          <small className="text-sm font-medium text-gray-600 dark:text-white">
            Etat (Justifié) :
          </small>
          <div>
            <select
              label="select Version"
              className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              defaultValue=""
              name="isJustify"
              value={filtredObject.isJustify}
              onChange={handleFiltersInputChange}
            >
              <option value="">Tous</option>
              <option value="true">Justifié</option>
              <option value="false">Non Justifié</option>
            </select>
          </div>
        </div>
        <div>
          <small className="text-sm font-medium text-gray-600 dark:text-white">
            Résponsable :
          </small>
          <div>
            <select
              label="select Version"
              className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              defaultValue="Tout"
              name="responsable"
              value={filtredObject.responsable}
              onChange={handleFiltersInputChange}
            >
              <option value="">Tous</option>
              {listOfTcp.map((el) => (
                <option value={el._id}>{el.Name}</option>
              ))}
            </select>
          </div>
        </div>
        <div>
          <small className="text-sm font-medium text-gray-600 dark:text-white">
            Type :
          </small>
          <div>
            <select
              label="select Version"
              className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              name="typeOfOp"
              value={filtredObject.typeOfOp}
              onChange={handleFiltersInputChange}
            >
              <option value="CV">Charges véhicules</option>
              <option value="FD">Frais de déplacement</option>
              <option value="TL">Frais de transport local</option>
            </select>
          </div>
        </div>

        <div>
          <small className="text-sm font-medium text-gray-600 dark:text-white">
            Type :
          </small>
          <div>
            <select
              label="select Version"
              className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              name="payeStatus"
              value={filtredObject.payeStatus}
              onChange={handleFiltersInputChange}
            >
              <option value="">Tous</option>
              <option value="N">En cours</option>
              <option value="payed">Payée</option>
              <option value="notPayed">Impayée</option>
            </select>
          </div>
        </div>

        <div>
          <small className="text-sm font-medium text-gray-600 dark:text-white">
            Etat DG :
          </small>
          <div>
            <select
              label="select Version"
              className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              name="dgValidationStatus"
              value={filtredObject.dgValidationStatus}
              onChange={handleFiltersInputChange}
            >
              <option value="">Tous</option>
              <option value="N">En cours</option>
              <option value="V">Validée</option>
              <option value="A">Annulée</option>
            </select>
          </div>
        </div>

        <div>
          <small className="text-sm font-medium text-gray-600 dark:text-white">
            Période (Depuis - à) :
          </small>
          <div>
            <input
              type="date"
              id=""
              placeholder=""
              className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              name="selectedFromDate"
              value={filtredObject.selectedFromDate}
              onChange={handleFiltersInputChange}
            />
            <input
              type="date"
              id=""
              placeholder=""
              className={`mt-2 flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              name="selectedToDate"
              value={filtredObject.selectedToDate}
              onChange={handleFiltersInputChange}
            />
          </div>
        </div>

        <div>
            <Button color="#236571" className="text-white mt-5" onClick={() => handleDownloadCompanyFeesPdf(filtredObject)}>Télécharger PDF</Button>
            <Button color="#236571" className="text-white mt-5" onClick={() => handleFilterCompanyFees()}>Filtrer</Button>
        </div>
      </div>

      {isLoading ? (
        <div className="flex w-full items-center justify-center">
          <Spinner className="h-12 w-12" />
        </div>
      ) : filtredObject.typeOfOp == "CV" && accessList.VehicleCharges ? (
        <CV
          tableData={tableData}
          refreshTableData={refreshTableData}
          accessList={accessList}
          showToast={handleShowToast}
          selectedFilters={filtredObject}
        />
      ) : filtredObject.typeOfOp == "FD" && accessList.TravellingExpenses ? (
        <FD
          tableData={tableData}
          refreshTableData={refreshTableData}
          accessList={accessList}
          showToast={handleShowToast}
          selectedFilters={filtredObject}
        />
      ) : filtredObject.typeOfOp == "TL" &&
        accessList.LocalTransportationCosts ? (
        <FTL
          tableData={tableData}
          refreshTableData={refreshTableData}
          accessList={accessList}
          showToast={handleShowToast}
          selectedFilters={filtredObject}
        />
      ) : (
        <></>
      )}
    </Card>
  );
}

export default CompanyFeesTable;
