import React, { useEffect, useState } from "react";
import { Button, Spinner } from "@material-tailwind/react";

import { BsPrinterFill } from "react-icons/bs";
import { getInvoceDataApi } from "api/clients";
import { getInvoceYearsApi } from "api/clients";
import { getOneClientApi } from "api/clients";
import { getInvocePdfDataApi } from "api/clients";
import { relevePrevYearsApi } from "api/clients";

import API_URL from "config";

function ClientInvoce({ closePopup, clientID }) {
  const [invoceData, setInvoceData] = useState([]);
  const [invoceYears, setInvoceYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getInitialData(selectedYear);
  }, [clientID]);

  const handleYearChange = async (e) => {
    try {
      setInvoceData(null)

      setIsLoading(true);
      await getInitialData(e.target.value);

      const clientDetails = await getOneClientApi(clientID);
      const invoceData = await getInvoceDataApi(
        clientDetails.ClientCode,
        e.target.value
      );
      setInvoceData(invoceData);
      setSelectedYear(e.target.value);
    }
    catch (err) {
      console.log("handleYearChange:", err)
    }
    finally {
      setIsLoading(false);
    }
  };

  const handleFilterBtn = async () => {
    try {
      setInvoceData(null)

      setIsLoading(true);
      await getInitialData(selectedYear);

      const clientDetails = await getOneClientApi(clientID);
      const invoceData = await getInvoceDataApi(
        clientDetails.ClientCode,
        selectedYear
      );
      setInvoceData(invoceData);
    }
    catch (err) {
      console.log(err)
    }
    finally {
      setIsLoading(false);
    }
  }

  const handleCloseReleveOp = () => {
    try {
      closePopup("Close");
    } catch (err) {}
  };

  const handlePrintClientList = async () => {
    try {
      const clientDetails = await getOneClientApi(clientID);
      const pdfInvoce = await getInvocePdfDataApi(
        clientDetails.ClientCode,
        selectedYear
      );
      window.open(`${pdfInvoce}`, "_blank");
    } catch (err) {
      console.log(err);
    }
  };

  const fetchPreviousYearsData = async (clientCode, years) => {
    // Map each year to a promise
    const promises = years.map(async (year) => await relevePrevYearsApi(clientCode, year));
    const promises2 = years.map(async (year) => await getInvoceDataApi(clientCode, year));

    try {
      // Execute all promises in parallel
      await Promise.all(promises);
      await Promise.all(promises2);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getInitialData = async (selectedYear) => {
    try {
      setIsLoading(true);
      const clientDetails = await getOneClientApi(clientID);

      const invoceYears = await getInvoceYearsApi(clientDetails.ClientCode);
      const filtredInvoceYears = invoceYears.filter((el) => el >= 2023)
      await Promise.all(
        filtredInvoceYears.map(async (el) => {
          return await relevePrevYearsApi(clientDetails.ClientCode, el);
        })
      );

      // await relevePrevYearsApi(clientDetails.ClientCode, new Date().getFullYear())
      // await relevePrevYearsApi(clientDetails.ClientCode, new Date().getFullYear()-1)
      // await relevePrevYearsApi(clientDetails.ClientCode, new Date().getFullYear()-2)
      await fetchPreviousYearsData(
        clientDetails.ClientCode,
        filtredInvoceYears
      );

      const invoceData = await getInvoceDataApi(
        clientDetails.ClientCode,
        selectedYear
      );

      setInvoceData(invoceData);
      setInvoceYears(invoceYears.filter((el) => el >= 2023).reverse());
      setSelectedYear(selectedYear)
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div className="grid grid-cols-1 gap-5 md:grid-cols-3">
        <div>
          <select
            label="select Version"
            className={`mt-2 flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
            defaultValue={selectedYear}
            onChange={handleYearChange}
          >
            {invoceYears.map((el) => (
              <option value={el}>{el}</option>
            ))}
          </select>
        </div>
        <div>
          <BsPrinterFill
            size={50}
            className="cursor-pointer"
            onClick={handlePrintClientList}
          />
        </div>
        <div>
                    <Button
                      onClick={() => handleFilterBtn()}
                      className="linear mb-5 rounded-[10px] bg-gray-500 px-4 py-2 text-xs font-bold text-white transition duration-200 hover:bg-gray-400 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:mb-0"
                    >
                      Actualiser
                    </Button>
        </div>
      </div>

      {!isLoading ? (
        <div style={{ height: "300px", overflowY: "scroll" }}>
          <table className="w-full">
            <thead>
              <tr>
                <th className="border-b border-gray-200 pr-28 pb-[10px] pt-3 pb-3 text-start dark:!border-navy-700">
                  <p className="text-sm tracking-wide text-gray-600">Date</p>
                </th>
                <th className="border-b border-gray-200 pr-28 pb-[10px] pt-3 pb-3 text-start dark:!border-navy-700">
                  <p className="text-sm tracking-wide text-gray-600">Jrl</p>
                </th>
                <th className="border-b border-gray-200 pr-28 pb-[10px] pt-3 pb-3 text-start dark:!border-navy-700">
                  <p className="text-sm tracking-wide text-gray-600">Libellé</p>
                </th>
                <th className="border-b border-gray-200 pr-28 pb-[10px] pt-3 pb-3 text-start dark:!border-navy-700">
                  <p className="text-sm tracking-wide text-gray-600">Débit</p>
                </th>
                <th className="border-b border-gray-200 pr-28 pb-[10px] pt-3 pb-3 text-start dark:!border-navy-700">
                  <p className="text-sm tracking-wide text-gray-600">Crédit</p>
                </th>
                <th className="border-b border-gray-200 pr-28 pb-[10px] pt-3 pb-3 text-start dark:!border-navy-700">
                  <p className="text-sm tracking-wide text-gray-600">Solde</p>
                </th>
              </tr>
            </thead>

            {invoceData?.releveData ? (
              <>
                <tbody>
                  {invoceData?.releveData.map((el) => (
                    <tr>
                      <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                        <p className="text-sm font-bold text-navy-700">
                          {el.Date}
                        </p>
                      </td>
                      <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                        <p className="text-sm font-bold text-navy-700">
                          {el.jtl}
                        </p>
                      </td>
                      <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                        {el.Type == "Effet" ||
                        el.Type == "Cheque" ||
                        el.Type == "Effet" ||
                        el.Type == "Effet" ? (
                          <p className="text-sm font-bold text-navy-700">
                            {el.Type} <br /> N°{el.Number} <br />{" "}
                            {el.DateEcheance}
                          </p>
                        ) : el.Type == "Espece" ||
                          el.Type == "Virement" ||
                          el.Type == "Cheque_facture" ? (
                          <p className="text-sm font-bold text-navy-700">
                            {el.Type} <br /> {el.Nfacture}
                          </p>
                        ) : el.jtl == "VT" || el.jtl == "AV" ? (
                          <p className="text-sm font-bold text-navy-700">
                            {" "}
                            {el.Type} <br /> #{el.FactureNumber}
                          </p>
                        ) : (
                          <p className="text-sm font-bold text-navy-700"></p>
                        )}
                      </td>
                      <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                        <p className="text-sm font-bold text-navy-700">
                          {parseFloat(el.Debit).toFixed(2)}
                        </p>
                      </td>
                      <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                        <p className="text-sm font-bold text-navy-700">
                          {parseFloat(el.Credit).toFixed(2)}
                        </p>
                      </td>
                      <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                        <p className="text-sm font-bold text-navy-700">
                          {parseFloat(el.Solde).toFixed(2)}
                        </p>
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td className="pt-[14px] pb-[18px]">
                      <p className="text-lg font-bold text-gray-500"></p>
                    </td>
                    <td className="pt-[14px] pb-[18px]">
                      <p className="text-lg font-bold text-gray-500"></p>
                    </td>
                    <td className="pt-[14px] pb-[18px]">
                      <p className="text-lg font-bold text-gray-500"></p>
                    </td>
                    <td className="pt-[14px] pb-[18px]">
                      <p className="text-lg font-bold text-gray-500">
                        {invoceData.sumOfReleve.D}
                      </p>
                    </td>
                    <td className="pt-[14px] pb-[18px]">
                      <p className="text-lg font-bold text-gray-500">
                        {invoceData.sumOfReleve.C}
                      </p>
                    </td>
                    <td className="pt-[14px] pb-[18px]">
                      <p className="text-lg font-bold text-gray-500"></p>
                    </td>
                  </tr>
                </tfoot>
              </>
            ) : (
              <></>
            )}
          </table>
        </div>
      ) : (
        <div className="flex items-center justify-center">
          <Spinner className="h-12 w-12" />
        </div>
      )}

      <div className="mt-5">
        <Button
          onClick={() => handleCloseReleveOp()}
          className="linear rounded-[10px] bg-gray-500 font-bold text-white transition duration-200 hover:bg-gray-400 active:bg-gray-900 dark:bg-gray-400 dark:hover:bg-gray-300 dark:active:opacity-90"
        >
          Fermer
        </Button>
      </div>
    </div>
  );
}

export default ClientInvoce;
