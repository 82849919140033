import React, { useEffect, useState } from "react";
import Card from "components/card";
import { getAllStocks } from "api/stock";
import { Button, Spinner, IconButton } from "@material-tailwind/react";
import { getAllDepotsApi } from "api/depot";
import { getAllTcpsApi } from "api/filtersData";

import {
  IoMdArrowDropleftCircle,
  IoMdArrowDroprightCircle,
} from "react-icons/io";

function DepotMovementsList() {
  const [isLoading, setIsLoading] = useState(false);

  const [listOfDepots, setListOfDepots] = useState([]);
  const [listOfTcp, setListOfTcp] = useState([]);
  const [tableData, setTableData] = useState([]);

  const [inputFilterValue, setInputFilterValue] = useState({
    Type: "",
    Depot: "",
    TCP: "",
    EnterOrExit: "",
    FromYear: `${new Date().getFullYear()}-01-01`,
    ToYear: `${new Date().getFullYear() + 1}-01-01`,
    Product: "",
    Category: "",
  });

  // paginations
  const [active, setActive] = useState(1);
  const [currentPagination, setCurrentPagination] = useState(1);

  const [numberOfPages, setNumberOfPages] = useState(0);
  const [numberOfPaginations, setNumberOfPaginations] = useState(0);
  const [numberOfRows, setNumberOfRows] = useState(0);

  useEffect(() => {
    getFiltersData();
  }, []);

  useEffect(() => {
    getInitialData(
      inputFilterValue.Type,
      inputFilterValue.Depot,
      inputFilterValue.TCP,
      inputFilterValue.EnterOrExit,
      inputFilterValue.FromYear,
      inputFilterValue.ToYear,
      inputFilterValue.Product,
      inputFilterValue.Category,
      active
    );
  }, []);

  const getFiltersData = async () => {
    try {
      const DepotsData = await getAllDepotsApi();
      const TcpData = await getAllTcpsApi();

      setListOfDepots(DepotsData);
      setListOfTcp(TcpData);
    } catch (err) {
      console.log(err);
    }
  };

  const getInitialData = async (
    typeOfOp,
    Depot,
    TCP,
    EnterOrExit,
    FromYear,
    ToYear,
    Product,
    Category,
    Page
  ) => {
    try {
      setIsLoading(true);

      const response = await getAllStocks(
        typeOfOp,
        Depot,
        TCP,
        EnterOrExit,
        FromYear,
        ToYear,
        Product,
        Category,
        Page
      );
      setTableData(response.data);

      // paginations
      setNumberOfPages(response.pagination.numberOfPages);
      setNumberOfPaginations(response.pagination.numberOfPaginations);
      setNumberOfRows(response.pagination.numberOfRows);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFiltersInputChange = (e) => {
    const { name, value } = e.target;
    setInputFilterValue((prevInputFilterValue) => ({
      ...prevInputFilterValue,
      [name]: value,
    }));

    getInitialData(
      name === "Type"
        ? value === "Invoce"
          ? ["Invoce"]
          : value === "Avoir"
          ? ["Avoir"]
          : value === "TranfertEntry"
          ? ["TranfertEntry", "TranfertExit"]
          : value === "StockEnter"
          ? ["StockEnter"]
          : value === "Add"
          ? ["Add", "Minus"]
          : ""
        : inputFilterValue.Type === "Invoce"
        ? ["Invoce"]
        : inputFilterValue.Type === "Avoir"
        ? ["Avoir"]
        : inputFilterValue.Type === "TranfertEntry"
        ? ["TranfertEntry", "TranfertExit"]
        : inputFilterValue.Type === "StockEnter"
        ? ["StockEnter"]
        : inputFilterValue.Type === "Add"
        ? ["Add", "Minus"]
        : "",

      name === "Depot" ? value : inputFilterValue.Depot,
      name === "TCP" ? value : inputFilterValue.TCP,
      name === "EnterOrExit" ? value : inputFilterValue.EnterOrExit,
      name === "FromYear" ? value : inputFilterValue.FromYear,
      name === "ToYear" ? value : inputFilterValue.ToYear,
      name === "Product" ? value : inputFilterValue.Product,
      name === "Category" ? value : inputFilterValue.Category,
      active
    );
  };

  // pagination
  const getItemProps = (index) => ({
    variant: active === index ? "filled" : "text",
    color: "gray",
    onClick: () => {
      getInitialData(
        inputFilterValue.Type === "Invoce"
          ? ["Invoce"]
          : inputFilterValue.Type === "Avoir"
          ? ["Avoir"]
          : inputFilterValue.Type === "TranfertEntry"
          ? ["TranfertEntry", "TranfertExit"]
          : inputFilterValue.Type === "StockEnter"
          ? ["StockEnter"]
          : inputFilterValue.Type === "Add"
          ? ["Add", "Minus"]
          : "",

        inputFilterValue.Depot,
        inputFilterValue.TCP,
        inputFilterValue.EnterOrExit,
        inputFilterValue.FromYear,
        inputFilterValue.ToYear,
        inputFilterValue.Product,
        "",
        index
      );
      setActive(index);
    },
  });

  const next = () => {
    if (currentPagination === numberOfPaginations) return;
    setCurrentPagination(currentPagination + 1);
  };

  const prev = () => {
    if (currentPagination === 1) return;
    setCurrentPagination(currentPagination - 1);
  };
  // pagination

  return (
    <Card extra={"w-full pb-10 p-4 h-full"}>
      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-4">
        <div>
          <small className="text-sm font-medium text-gray-600 dark:text-white">
            Filtrer par Dépôt :
          </small>
          <div>
            <select
              label="select Version"
              className={`mt-2 flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              defaultValue=""
              name="Depot"
              value={inputFilterValue.Depot}
              onChange={handleFiltersInputChange}
            >
              <option value="">Tout</option>
              {listOfDepots.map((el) => (
                <option value={el.Name}>
                  {el.Origin} ({el.Name})
                </option>
              ))}
            </select>
          </div>
        </div>
        <div>
          <small className="text-sm font-medium text-gray-600 dark:text-white">
            Filtrer par TCP :
          </small>
          <div>
            <select
              label="select Version"
              className={`mt-2 flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              defaultValue=""
              name="TCP"
              value={inputFilterValue.TCP}
              onChange={handleFiltersInputChange}
            >
              <option value="">Tout</option>
              {listOfTcp.map((el) => (
                <option value={el._id}>{el.Name}</option>
              ))}
            </select>
          </div>
        </div>
        <div>
          <small className="text-sm font-medium text-gray-600 dark:text-white">
            Sortie / Entrée :
          </small>
          <div>
            <select
              label="select Version"
              className={`mt-2 flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              defaultValue=""
              name="EnterOrExit"
              value={inputFilterValue.EnterOrExit}
              onChange={handleFiltersInputChange}
            >
              <option value="">Tout</option>
              <option value="Enter">Entrée</option>
              <option value="Exit">Sortée</option>
            </select>
          </div>
        </div>
        <div>
          <small className="text-sm font-medium text-gray-600 dark:text-white">
            Type d'opération :
          </small>
          <div>
            <select
              label="select Version"
              className={`mt-2 flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              defaultValue=""
              name="Type"
              value={inputFilterValue.Type}
              onChange={handleFiltersInputChange}
            >
              <option value="">Tout</option>
              <option value="Invoce">BL</option>
              <option value="Avoir">BR</option>
              <option value="TranfertEntry">Transfert de stock</option>
              <option value="StockEnter">Entrée de stock</option>
              <option value="Add">Alimentation de stock</option>
            </select>
          </div>
        </div>
        <div>
          <small className="text-sm font-medium text-gray-600 dark:text-white">
            Filtrer par date (depuis - à) :
          </small>
          <div>
            <input
              type="date"
              id=""
              placeholder=""
              className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              name="FromYear"
              value={inputFilterValue.FromYear}
              onChange={handleFiltersInputChange}
            />
            <input
              type="date"
              id=""
              placeholder=""
              className={`mt-2 flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              name="ToYear"
              value={inputFilterValue.ToYear}
              onChange={handleFiltersInputChange}
            />
          </div>
        </div>
      </div>

      {isLoading ? (
        <div className="flex w-full items-center justify-center">
          <Spinner className="h-12 w-12" />
        </div>
      ) : (
        <>
          <div
            className="mt-8 overflow-x-scroll"
            style={{ height: "40vh", overflowY: "scroll" }}
          >
            <table className="w-full">
              <thead>
                <tr>
                  <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                    <p className="text-xs tracking-wide text-gray-600">
                      Date OP
                    </p>
                  </th>
                  <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                    <p className="text-xs tracking-wide text-gray-600">
                      Mode OP
                    </p>
                  </th>
                  <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                    <p className="text-xs tracking-wide text-gray-600">Dépôt</p>
                  </th>
                  <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                    <p className="text-xs tracking-wide text-gray-600">
                      Produit
                    </p>
                  </th>
                  <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                    <p className="text-xs tracking-wide text-gray-600">
                      Catégorie
                    </p>
                  </th>
                  <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                    <p className="text-xs tracking-wide text-gray-600">
                      Quantité
                    </p>
                  </th>
                  <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                    <p className="text-xs tracking-wide text-gray-600">
                      Reste en stock
                    </p>
                  </th>
                  <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                    <p className="text-xs tracking-wide text-gray-600">
                      Responsable
                    </p>
                  </th>
                  <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                    <p className="text-xs tracking-wide text-gray-600">
                      Client
                    </p>
                  </th>
                  <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                    <p className="text-xs tracking-wide text-gray-600">
                      Transferée par
                    </p>
                  </th>
                </tr>
              </thead>

              <tbody>
                {tableData.map((el) => (
                  <tr
                    style={{
                      backgroundColor:
                        el.StockType === "TranfertEntry" || el.StockType === "TranfertExit" ?
                        el.Type === "Enter" ? "#ebcccc" : "#f7fafe"
                        : el.Type === "Enter" ? "#f7fafe" : "#ebcccc",
                    }}
                  >
                    <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                      <p className="text-sm font-bold text-navy-700 dark:text-white">
                        {/* {el.CurrentDate.split('T')[0].split('-')[2]}-{el.CurrentDate.split('T')[0].split('-')[1]}-{el.CurrentDate.split('T')[0].split('-')[0]} */}
                        {el.CurrentDate.split("T")[0]?.split('-')?.reverse()?.join('-')}
                      </p>
                    </td>
                    <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                      <p className="text-sm font-bold text-navy-700 dark:text-white">
                        {el.StockType === "TranfertEntry"
                          ? "Transfer of stock"
                          : el.StockType === "TranfertExit"
                          ? "Transfer of stock"
                          : el.StockType === "Add"
                          ? "Alimentation de stock"
                          : el.StockType === "Minus"
                          ? "Alimentation de stock"
                          : el.StockType === "StockEnter"
                          ? "Entrée de stock"
                          : el.StockType === "Invoce"
                          ? "Stock"
                          : el.StockType === "Avoir"
                          ? "Avoir"
                          : ""}
                      </p>
                    </td>
                    <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                      <p className="text-sm font-bold text-navy-700 dark:text-white">
                        {el?.Depot}
                      </p>
                    </td>
                    <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                      <p className="text-sm font-bold text-navy-700 dark:text-white">
                        {el?.Product}
                      </p>
                    </td>
                    <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                      <p className="text-sm font-bold text-navy-700 dark:text-white">
                        {el?.Category?.Name}
                      </p>
                    </td>
                    <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                      <p className="text-sm font-bold text-navy-700 dark:text-white">
                        {el?.Quantity ? parseFloat(el.Quantity).toFixed(2) : ""}
                      </p>
                    </td>
                    <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                      <p className="text-sm font-bold text-navy-700 dark:text-white">
                        {el?.RestByDepot?.Disponible}
                      </p>
                    </td>
                    <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                      <p className="text-sm font-bold text-navy-700 dark:text-white">
                        {el?.Operator?.Name}
                      </p>
                    </td>
                    <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                      <p className="text-sm font-bold text-navy-700 dark:text-white">
                        {el?.Client?.Name}
                      </p>
                    </td>
                    <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                      <p className="text-sm font-bold text-navy-700 dark:text-white">
                        {el.ChangedByOperator
                          ? el.ChangedByOperator.Name
                          : "Admin"}
                      </p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* {
            <div className="mt-4 flex items-center gap-4">
              <Button
                variant="text"
                className="flex items-center gap-2"
                onClick={prev}
                disabled={currentPagination === 1}
              >
                <IoMdArrowDropleftCircle size={30} />
              </Button>
              <div className="flex items-center gap-2">
                {currentPagination !== numberOfPaginations
                  ? // paginations row
                    Array.from({
                      length: numberOfPages > 5 ? 5 : numberOfPages,
                    }).map((el, index) => {
                      const pageNumber =
                        index + 1 + (currentPagination - 1) * 10;
                      return (
                        <IconButton {...getItemProps(pageNumber)}>
                          {pageNumber}
                        </IconButton>
                      );
                    })
                  : // last pagination row
                    Array.from({ length: numberOfPages % 10 }).map(
                      (el, index) => {
                        const pageNumber =
                          index + 1 + (currentPagination - 1) * 5;
                        return (
                          <IconButton {...getItemProps(pageNumber)}>
                            {pageNumber}
                          </IconButton>
                        );
                      }
                    )}
              </div>
              <Button
                variant="text"
                className="flex items-center gap-2"
                onClick={next}
                disabled={
                  currentPagination === numberOfPaginations ||
                  numberOfPaginations === 0
                }
              >
                <IoMdArrowDroprightCircle size={30} />
              </Button>
            </div>
          } */}
        </>
      )}
    </Card>
  );
}

export default DepotMovementsList;
